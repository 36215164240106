export function pickMany<T>(array: T[], count: number): T[] {
  if (count > array.length) {
    throw new Error("Cannot pick more items than are in the array");
  }
  const result: T[] = [];
  const picked = new Set<number>();
  for (let i = 0; i < count; i++) {
    let index: number;
    do {
      index = Math.floor(Math.random() * array.length);
    } while (picked.has(index));
    result.push(array[index]);
    picked.add(index);
  }
  return result;
}

export function pickOne<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

export function shuffle<T>(array: T[]): T[] {
  const result = [...array];
  for (let i = 0; i < result.length; i++) {
    const j = Math.floor(Math.random() * result.length);
    const temp = result[i];
    result[i] = result[j];
    result[j] = temp;
  }
  return result;
}
