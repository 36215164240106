import sortBy from "just-sort-by";
import { pickMany } from "@/lib/random";
import { YearChoice, ScenarioSetup } from "@/lib/types";
import { tmpl } from "../template";

type RawYearChoice = {
  year: string | number;
  reason: string;
};

export function getRandomYears(): YearChoice[] {
  const results = [
    ...pickMany(ancientYears, 2),
    ...pickMany(westernCivYears, 3),
    ...pickMany(americaYears, 2),
    ...pickMany(asiaYears, 2),
    ...pickMany(africaYears, 1),
    ...pickMany(oceanaYears, 1),
  ];
  return sortBy<YearChoice>(results, (i) => i.year);
}

export function formatYear(year: number | string): string {
  if (typeof year !== "number") {
    console.warn("Got a year that isn't a number:", year);
    if (typeof year === "string") {
      year = parseInt(year, 10);
    } else if (!year) {
      return "[empty year]";
    } else {
      throw new Error("Received a non-year to formatYear");
    }
  }
  if (year < 0) {
    return `${Math.abs(year) + 1}BC`;
  }
  return year.toString();
}

export function parseYear(text: string): number {
  let n = text.replace(",", "");
  n = n.replace(/A\.?D\.?C?\.?/i, "");
  n = n.replace(/B\.?C\.?E?\.?/i, "BC");
  if (n.includes("BC")) {
    return -(parseInt(n.replace("BC", ""), 10) - 1);
  }
  return parseInt(n, 10);
}

export function normalizeYears(years: RawYearChoice[]): YearChoice[] {
  const result = years.map((y): YearChoice => {
    let n = y.year;
    if (typeof n !== "number") {
      n = parseYear(n);
    }
    return { year: n, reason: y.reason || "" };
  });
  return sortBy<YearChoice>(result, (x): number => x.year);
}

export function formatScenarioSetupDate(scenarioSetup: ScenarioSetup): string {
  return `${formatYear(scenarioSetup.year)}-${scenarioSetup.month}-${scenarioSetup.day}`;
}

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July ",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function formatScenarioSetupDateForHuman(scenarioSetup: ScenarioSetup): string {
  const month = MONTHS[scenarioSetup.month - 1];
  return tmpl`
  ${scenarioSetup.time} on ${scenarioSetup.day} ${month}, ${formatYear(scenarioSetup.year)}
  `;
}

// FIXME: need to split this between modern (1900+) and pre-modern
export const westernCivYears = normalizeYears([
  { year: "305AD", reason: "Born before the Council of Nicea in 325." },
  { year: "456AD", reason: "Witnessing the fall of the Western Roman Empire." },
  { year: "500BC", reason: "In 480BC, the Battle of Thermopylae occurs." },
  { year: "773AD", reason: "Witnessing the start of the Viking Age." },
  {
    year: "750AD",
    reason: "Witnessing the Viking Age in the 8th and 9th centuries.",
  },
  { year: "100", reason: "In 120, the Roman Empire is at its height." },
  {
    year: "1045",
    reason: "Alive during the Norman Conquest of England in 1066.",
  },
  { year: "1074", reason: "Born before the First Crusade in 1096." },
  {
    year: "1180",
    reason: "Witnessing the start of the Fourth Crusade in 1202.",
  },
  { year: "1190", reason: "Born right before the Mongol invasions." },
  { year: "1305", reason: "Around during the Hundred Years' War in 1337." },
  {
    year: "1337",
    reason: "Born right before the Black Death pandemic in 1348.",
  },
  { year: "1433", reason: "Seeing the fall of Constantinople in 1453." },
  { year: "1430", reason: "Born before the fall of Constantinople in 1453." },
  {
    year: "1472",
    reason: "Seeing Leonardo da Vinci's 'The Last Supper' in 1498.",
  },
  {
    year: "1465",
    reason: "Able to witness Christopher Columbus's journey to the Americas in 1492.",
  },
  { year: "1490", reason: "Witnessing the Age of Discovery." },
  { year: "1500", reason: "Alive during the Protestant Reformation in 1517." },
  {
    year: "1510",
    reason: "Experiencing Henry VIII's break from the Catholic Church in 1533.",
  },
  {
    year: "1557",
    reason: "Around for the first English colony in North America, Roanoke Island, in 1587.",
  },
  { year: "1544", reason: "Witnessing the start of the Dutch Revolt in 1568." },
  { year: "1566", reason: "Alive during the Spanish Armada's defeat in 1588." },
  {
    year: "1568",
    reason: "Seeing the first performance of Shakespeare's Hamlet in 1600.",
  },
  {
    year: "1580",
    reason: "Seeing the establishment of the Jamestown Colony in 1607.",
  },
  {
    year: "1580",
    reason: "Alive during the publication of the King James Bible in 1611.",
  },
  {
    year: "1597",
    reason: "Living through the start of the Thirty Years' War in 1618.",
  },
  {
    year: "1620",
    reason: "Experience the start of the English Civil War in 1642.",
  },
  { year: "1646", reason: "Can witness the Great Fire of London in 1666." },
  { year: "1685", reason: "Before the War of Spanish Succession in 1701." },
  {
    year: "1705",
    reason: "Seeing the War of the Austrian Succession in 1740.",
  },
  {
    year: "1740",
    reason: "Around for the start of the Industrial Revolution.",
  },
  {
    year: "1734",
    reason: "Witnessing the start of the French and Indian War in 1754.",
  },
  {
    year: "1748",
    reason: "Living through the American Revolutionary War in 1775.",
  },
  {
    year: "1737",
    reason: "Seeing the start of the Industrial Revolution in 1760.",
  },
  {
    year: "1775",
    reason: "Born right before the U.S. Declaration of Independence in 1776.",
  },
  { year: "1767", reason: "Witnessing the French Revolution in 1789." },
  {
    year: "1757",
    reason: "Witnessing the invention of the steamboat in 1787.",
  },
  {
    year: "1779",
    reason: "Witnessing the start of the Napoleonic Wars in 1803.",
  },
  {
    year: "1800",
    reason: "Witnessing the start of the American Westward Expansion.",
  },
  {
    year: "1800",
    reason: "Witnessing the start of the Greek War of Independence in 1821.",
  },
  {
    year: "1800",
    reason: "Alive during the Mexican War of Independence in 1821.",
  },
  {
    year: "1808",
    reason: "Witnessing the start of the Victorian Era in 1837.",
  },
  {
    year: "1825",
    reason: "Alive during the start of the Great Migration in 1845.",
  },
  {
    year: "1840",
    reason: "Seeing the start of the American Civil War in 1861.",
  },
  { year: "1870", reason: "Seeing the start of the Gilded Age in America." },
  { year: "1870", reason: "Born during the height of the British Empire." },
  { year: "1862", reason: "Witnessing the height of the Wild West." },
  {
    year: "1888",
    reason: "Experiencing the start of the Second Industrial Revolution.",
  },
  { year: "1890", reason: "Alive during World War I." },
  { year: "1900", reason: "Witnessing the start of the Roaring Twenties." },
  { year: "1909", reason: "Living through the Great Depression." },
  { year: "1915", reason: "Witnessing the rise of Nazi Germany." },
  { year: "1921", reason: "Alive during World War II." },
  { year: "1938", reason: "Seeing the start of the Cold War." },
  { year: "1947", reason: "Seeing the start of the Civil Rights Movement." },
  { year: "1950", reason: "Witnessing the start of the Vietnam War." },
  { year: "1970", reason: "Witnessing the fall of the Berlin Wall in 1989." },
  { year: "1972", reason: "Witnessing the start of the Yugoslav Wars." },
  { year: "1980", reason: "Seeing the start of the War on Terror." },
  { year: "1990", reason: "Seeing the start of the Syrian Civil War in 2011." },
]);

export const ancientYears = normalizeYears([
  {
    year: "70,000BC",
    reason:
      "Witnessing the Toba supereruption and the potential resulting 'genetic bottleneck' in human evolution.",
  },
  {
    year: "60,000BC",
    reason:
      "Living during the time of the earliest evidence of human migration out of Africa, moving into the Middle East.",
  },
  {
    year: "45,000BC",
    reason:
      "Seeing the start of the Upper Paleolithic period, known for its significant evolution in human culture and tools.",
  },
  {
    year: "40,000BC",
    reason:
      "Living during the time when humans first entered Europe and began to create cave paintings, like those in Lascaux, France.",
  },
  {
    year: "14,000BC",
    reason:
      "Living during the transition from Paleolithic to Neolithic, when humans began to develop agriculture.",
  },
  {
    year: "10,000BC",
    reason:
      "Seeing the start of the Neolithic Revolution, the shift from hunting and gathering to settled farming.",
  },
  {
    year: "3000BC",
    reason: "Living during the dawn of the Ancient Egyptian civilization.",
  },
  {
    year: "2700BC",
    reason: "Witnessing the construction of the Egyptian pyramids.",
  },
  {
    year: "1600BC",
    reason: "Seeing the rise of the Mycenaean civilization in Greece.",
  },
  {
    year: "1500BC",
    reason: "Experiencing the era of the Rigveda, the oldest of the Hindu scriptures.",
  },
  {
    year: "1200BC",
    reason: "Seeing the end of the Bronze Age and the beginning of the Iron Age.",
  },
  {
    year: "1204BC",
    reason: "Living during the alleged time of the Trojan War.",
  },
  {
    year: "1100BC",
    reason: "Witnessing the rise of the Phoenician civilization.",
  },
  {
    year: "1000BC",
    reason: "Living during the reign of King David in ancient Israel.",
  },
  { year: "796BC", reason: "Alive during the first recorded Olympic games." },
  {
    year: "773BC",
    reason: "Witnessing the traditional date of the founding of Rome.",
  },
  {
    year: "742BC",
    reason: "Seeing the fall of the Kingdom of Israel to the Assyrians.",
  },
  { year: "600BC", reason: "Living during the life of the Buddha." },
  { year: "583BC", reason: "Witnessing the birth of Gautama Buddha." },
  { year: "529BC", reason: "Experiencing the founding of the Roman Republic." },
  { year: "500BC", reason: "In 480BC, the Battle of Thermopylae occurs." },
  { year: "490BC", reason: "Living during the life of Socrates." },
  {
    year: "460BC",
    reason: "Seeing the peak of the Athenian empire under Pericles.",
  },
  { year: "44BC", reason: "Alive during the rise of the Roman Empire." },
  { year: "420BC", reason: "Witnessing the Persian invasion of Greece." },
  {
    year: "351BC",
    reason: "Experiencing Alexander the Great's conquest of Persia.",
  },
  {
    year: "320BC",
    reason: "Living during the reign of Ashoka the Great in India.",
  },
  {
    year: "284BC",
    reason: "Witnessing the start of the Punic Wars between Rome and Carthage.",
  },
  { year: "238BC", reason: "Seeing Hannibal's crossing of the Alps." },
  {
    year: "188BC",
    reason: "Living during the Roman Republic's victory over Macedon.",
  },
  { year: "166BC", reason: "Witnessing the Roman conquest of Greece." },
  { year: "120BC", reason: "Living during the birth of Julius Caesar." },
  { year: "93BC", reason: "Witnessing the slave revolt led by Spartacus." },
  {
    year: "80BC",
    reason: "Experiencing the formation of the First Triumvirate in Rome.",
  },
  { year: "64BC", reason: "Seeing the assassination of Julius Caesar." },
  { year: "24BC", reason: "Living during the birth of Jesus Christ." },
]);

export const americaYears = normalizeYears([
  {
    year: "5000BC",
    reason:
      "Witnessing the development of the first pottery in the Americas, around the Amazon basin.",
  },
  {
    year: "3200BC",
    reason:
      "Seeing the development of the first urban settlement in America at Caral-Supe in modern-day Peru.",
  },
  {
    year: "1800BC",
    reason:
      "Living during the start of the Olmec civilization, considered the first major civilization in Mexico.",
  },
  {
    year: "1500BC",
    reason: "Experiencing the Olmecs carving the first colossal heads.",
  },
  { year: "1200BC", reason: "Seeing the rise of the Chavín culture in Peru." },
  {
    year: "600BC",
    reason: "Living during the start of the Zapotec civilization in Oaxaca, Mexico.",
  },
  {
    year: "400BC",
    reason: "Witnessing the rise of the city of Teotihuacan in Mexico.",
  },
  {
    year: "100BC",
    reason: "Seeing the construction of the Pyramid of the Sun in Teotihuacan.",
  },
  {
    year: "300AD",
    reason: "Experiencing the height of the city of Teotihuacan.",
  },
  {
    year: "600AD",
    reason: "Witnessing the start of the Classic Maya period in Mesoamerica.",
  },
  {
    year: "700AD",
    reason:
      "Living during the construction of Pueblo Bonito in Chaco Canyon by the Ancestral Puebloans.",
  },
  {
    year: "800AD",
    reason:
      "Seeing the peak of the city of Tikal, one of the largest urban centers of the pre-Columbian Maya civilization.",
  },
  {
    year: "900AD",
    reason:
      "Experiencing the Mississippian culture start to construct mound complexes in the southeastern United States.",
  },
  {
    year: "1000AD",
    reason: "Living during the Viking expedition to Vinland, now known as Newfoundland, Canada.",
  },
  {
    year: "1200AD",
    reason: "Witnessing the rise of the Inca civilization in South America.",
  },
  {
    year: "1305AD",
    reason: "Seeing the foundation of Tenochtitlán, the capital of the Aztec Empire.",
  },
  {
    year: "1400AD",
    reason:
      "Living during the reign of the Inca emperor Pachacuti, who transformed the city of Cusco into the empire's capital.",
  },
  { year: "1420AD", reason: "Witnessing the construction of Machu Picchu." },
  {
    year: "1450AD",
    reason: "Experiencing the height of the Aztec civilization.",
  },
  {
    year: "1471AD",
    reason: "Living just before the arrival of Christopher Columbus in the Americas.",
  },
]);

export const asiaYears = normalizeYears([
  {
    year: "2600BC",
    reason:
      "Witnessing the construction of the Indus Valley Civilization's cities like Harappa and Mohenjo-Daro.",
  },
  {
    year: "1600BC",
    reason: "Living during the Shang Dynasty, China's first recorded dynasty.",
  },
  {
    year: "1200BC",
    reason: "Seeing the rise of the Vedic civilization in India.",
  },
  {
    year: "600BC",
    reason: "Living during the time of Mahavira, the founder of Jainism, and Buddha in India.",
  },
  {
    year: "241BC",
    reason: "Witnessing the unification of China under Emperor Qin Shi Huang.",
  },
  {
    year: "222BC",
    reason: "Experiencing the start of the Han Dynasty in China.",
  },
  { year: "100", reason: "Seeing the spread of Buddhism along the Silk Road." },
  {
    year: "300",
    reason: "Living during the start of the Gupta Empire in India.",
  },
  {
    year: "579",
    reason: "Witnessing the reunification of China under the Sui Dynasty.",
  },
  {
    year: "600",
    reason: "Living during the time Prophet Muhammad received the first revelation of the Quran.",
  },
  {
    year: "690",
    reason: "Experiencing the Nara period, the first centralized government in Japan.",
  },
  { year: "774", reason: "Witnessing the start of the Heian period in Japan." },
  {
    year: "940",
    reason: "Living during the start of the Song Dynasty in China.",
  },
  {
    year: "1024",
    reason: "Seeing the construction of the Angkor Wat temple complex in Cambodia.",
  },
  {
    year: "1165",
    reason: "Experiencing the start of the Kamakura Shogunate in Japan.",
  },
  {
    year: "1186",
    reason: "Witnessing the establishment of the Mongol Empire under Genghis Khan.",
  },
  {
    year: "1372",
    reason: "Living during the start of the Joseon Dynasty in Korea.",
  },
  {
    year: "1506",
    reason: "Seeing the establishment of the Mughal Empire in India.",
  },
  { year: "1572", reason: "Witnessing the Japanese invasions of Korea." },
  {
    year: "1600",
    reason: "Experiencing the start of the Tokugawa Shogunate in Japan.",
  },
  {
    year: "1624",
    reason: "Living during the start of the Qing Dynasty, the last imperial dynasty of China.",
  },
  {
    year: "1670",
    reason: "Witnessing the Battle of Plassey, the beginning of British colonial rule in India.",
  },
  {
    year: "1768",
    reason: "Seeing the establishment of the Nguyen Dynasty in Vietnam.",
  },
  { year: "1819", reason: "Experiencing the start of the Opium War in China." },
  {
    year: "1833",
    reason:
      "Witnessing the arrival of Commodore Perry's ships in Japan, opening the country to the Western world.",
  },
  { year: "1848", reason: "Living during the Meiji Restoration in Japan." },
  { year: "1874", reason: "Seeing the start of the First Sino-Japanese War." },
  {
    year: "1901",
    reason: "Living during the Chinese Revolution and the end of the Qing Dynasty.",
  },
  {
    year: "1927",
    reason: "Experiencing the independence and partition of India and Pakistan.",
  },
  { year: "1930", reason: "Seeing the start of the Korean War." },
  { year: "1951", reason: "Experiencing the Bangladesh Liberation War." },
  { year: "1955", reason: "Seeing the end of the Vietnam War." },
  {
    year: "1966",
    reason: "Witnessing the People Power Revolution in the Philippines.",
  },
  { year: "1991", reason: "Seeing the start of the War in Afghanistan." },
]);

export const africaYears = normalizeYears([
  {
    year: "7000BC",
    reason:
      "Living during the African Neolithic period, witnessing the shift from hunter-gatherer cultures to settled farming communities.",
  },
  {
    year: "3150BC",
    reason:
      "Seeing the unification of Upper and Lower Egypt, marking the beginning of Pharaonic rule.",
  },
  {
    year: "2650BC",
    reason:
      "Living during the time of Pharaoh Djoser and the construction of the first pyramid at Saqqara.",
  },
  {
    year: "570BC",
    reason:
      "Witnessing the construction of the Great Zimbabwe, a significant stone-walled settlement.",
  },
  {
    year: "100AD",
    reason:
      "Experiencing the Axumite Empire in Ethiopia, one of the four known kingdoms of the ancient world.",
  },
  {
    year: "3100BC",
    reason: "Living during the unification of Upper and Lower Egypt.",
  },
  {
    year: "2000BC",
    reason: "Seeing the rise of the Kingdom of Kerma in Nubia.",
  },
  {
    year: "800BC",
    reason: "Witnessing the establishment of the city of Carthage by the Phoenicians.",
  },
  {
    year: "590BC",
    reason:
      "Experiencing the reign of the 25th Dynasty of Egypt, also known as the Nubian Dynasty.",
  },
  {
    year: "222BC",
    reason: "Living during the end of the Second Punic War and the rise of Rome.",
  },
  {
    year: "310",
    reason: "Seeing the rise of the Aksumite Empire in modern day Ethiopia.",
  },
  {
    year: "622",
    reason: "Witnessing the Arab conquest of Egypt and the decline of the Byzantine control.",
  },
  {
    year: "800",
    reason: "Experiencing the height of the Ghana Empire, West Africa's first known kingdom.",
  },
  { year: "1215", reason: "Living during the rise of the Mali Empire." },
  {
    year: "1462",
    reason: "Seeing the arrival of the Portuguese on the Gold Coast (modern Ghana).",
  },
  {
    year: "1632",
    reason: "Experiencing the establishment of Cape Colony by the Dutch East India Company.",
  },
  {
    year: "1784",
    reason: "Living during the establishment of the Sokoto Caliphate by Usman dan Fodio.",
  },
  {
    year: "1890",
    reason: "Living during the formation of the Union of South Africa.",
  },
  {
    year: "1932",
    reason: "Witnessing the start of the Mau Mau Uprising in Kenya.",
  },
  {
    year: "1937",
    reason:
      "Experiencing Ghana's independence from Britain, the first Sub-Saharan African country to do so.",
  },
  {
    year: "1940",
    reason: "Living during the 'Year of Africa', when 17 African nations gained independence.",
  },
  {
    year: "1946",
    reason: "Witnessing the South African District Six forced removals under the Group Areas Act.",
  },
  { year: "1960", reason: "Living during Zimbabwe's independence." },
  {
    year: "1964",
    reason: "Experiencing the end of apartheid and the first democratic elections in South Africa.",
  },
  { year: "1964", reason: "Living during the Rwandan Genocide." },
]);

export const oceanaYears = normalizeYears([
  {
    year: "30000BC",
    reason: "Living during the time when early humans first arrived in Australia.",
  },
  {
    year: "13000BC",
    reason: "Witnessing the earliest known human settlements in the Mariana Islands.",
  },
  {
    year: "1300BC",
    reason: "Experiencing the start of the Lapita cultural period in the Pacific Islands.",
  },
  {
    year: "300AD",
    reason:
      "Living during the development of the Polynesian culture and the construction of Nan Madol in Micronesia.",
  },
  {
    year: "1000AD",
    reason: "Experiencing the development and flourishing of the Maori culture in New Zealand.",
  },
  {
    year: "1200AD",
    reason: "Living during the time of the construction of the first moai on Easter Island.",
  },
  {
    year: "1300",
    reason: "Living during the migration and settlement of the Maori people in New Zealand.",
  },
  {
    year: "1590",
    reason:
      "Witnessing the first known European exploration of Australia by the Dutch explorer Willem Janszoon.",
  },
  {
    year: "1754",
    reason:
      "Experiencing Captain James Cook's first voyage and his visit to Tahiti and New Zealand.",
  },
  {
    year: "1778",
    reason: "Living during the establishment of the first British colony in Australia at Sydney.",
  },
  { year: "1831", reason: "Experiencing the Australian gold rush." },
  {
    year: "1922",
    reason: "Witnessing the Battle of Midway and the Guadalcanal Campaign during World War II.",
  },
]);
